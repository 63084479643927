body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(https://safegamecash.net/img/xacid-min.jpg.pagespeed.ic.UlUrwkTI-L.webp);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
table{
  text-align:center;
  margin:0px auto;
  color:#fff;
  font-weight:bold;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
